export var langSe = {
    site: {
        name: 'Artikulationsskolan',
        name_short: 'AS',
        secondary_name: 'Terapistigen',
        logo: '/assets/images/artikulationsskolan-logo.png',
        logo_square: '/assets/images/as-logo.png',
    },
    nav: {
        modules: 'Moduler',
        search: 'Sök med namn',
        home: 'Hem',
        periods: 'Terapiperioder',
        active_periods: 'Aktiva terapiperioder',
        new_clients: 'Nya klienter',
        new_periods: 'Nya terapiperioder',
        end_reports: 'Sammanfattningar',
        periodhistory: 'Tidigare terapiperioder',
        period: 'Terapiperiod',
        evaluations: 'Bedömning av servicebehov',
        questioinnaires: 'Blanketter',
        assignments: 'Övningar',
        material_library: 'Materialbank',
        plan_assignments: 'Uppgiftsplanering',
        messages: 'Meddelanden',
        links: 'Länkar',
        share_license: 'Skicka licenser',
        quick_help: 'Snabbhjälp',
        instructions: 'Instruktioner',
        customer_service: 'Kundservice',
        profile: 'Profil',
        log_out: 'Logga ut',
        close: 'Stänga',
        users: 'Användare',
    },
    login: {
        welcome: 'Logga in på artikulationsskolan',
        enter_username: 'Användarnamn',
        username: 'användarnamn',
        enter_password: 'Lösenord',
        password: 'lösenord',
        login: 'Logga in',
        forgotten_password: 'Jag har glömt mitt lösenord',
        login_no_match: 'Användarnamn och lösenord passar inte',
        login_failed: 'Inloggningen misslyckades',
        username_missing: 'Ett användarnamn krävs för att logga in',
        password_missing: 'Ett lösenord krävs för att logga in',
        confirm_username: 'Bekräfta användarnamn',
    },
    home: {
        licence_controller: 'Licensadministratör',
        admin: 'Admin',
        therapists: 'Terapeuter',
        loading_user_information: 'Laddar användaruppgifter',
        welcome: 'Välkommen till artikulationsskolan',
        therapist_announcements: 'Terapeuten informerar',
        your_therapist: 'Din terapeut',
        your_own_therapist: 'Din terapeut',
        your_periods: 'Era terapiperioder',
        common_practice_instructions: 'Allmänna instruktioner för träningen',
        today: 'Idag är det',
        you_have: 'Du har',
        view_all: 'Se alla',
        new_clients: 'nya klienter',
        new_messages: 'nya meddelanden',
        end_reports: 'väntar på slutrapport',
        periods_new: "nya perioder",
        periods_passive: 'passiva terapiperioder',
        periods_ended: 'terapiperioder som tagit slut',
        own_free: 'Mina lediga klientplatser',
        total_free: 'Lediga klientplatser på hela artikulationsskolan',
        free: 'Lediga klientplatser',
        active: 'Käynnissä olevat jaksot',
        write_end_reports: 'Skriv terapiutlåtanden',
        client: 'Klient',
        therapist: 'Terapeut',
        content: 'Innehåll',
        phase: 'Terapigånger',
        last_message: 'Senaste meddelandet',
        sent_time: 'Meddelande skickat',
        message: 'Meddelande',
        current: 'Aktuellt',
        front_page: 'Framsida',
        new_msg_practice_info: 'Det finns nya meddelanden och övningar för dig',
        info: 'Information',
    },
    period: {
        message_date_format: "DD.MM.YYYY HH:mm",
        delete_message_confirmation: 'Haluatko varmasti poistaa viestin?',
        update_therapist_note: 'Muokkaa muistiinpanoja',
        last_ended_period: 'Senast avslutad terapiperiod',
        active_period: 'Aktiv terapiperiod',
        ended_periods: 'Avslutade terapiperioder',
        end_date: 'Jakso päättyy',
        add_new_guardian: 'Lägg till ytterligare vårdnadshavare',
        search_result: 'Sökresultat',
        started: 'Startdatum',
        ended: 'Päättymis pvm',
        customers_without_period: 'Klienter utan aktiv terapiperiod',
        all_periods: 'Kaikki jaksot',
        only_active_periods: 'Vain käynnissä olevat jaksot',
        only_all_ended_periods: 'Vain päättyneet jaksot',
        only_reasently_ended_periods: 'Vain päättyneet jaksot (viimeiset 3kk)',
        period_time: 'Terapiperiodens varaktighet',
        sessions_completed_start: 'Gång',
        sessions_completed_end: 'använd',
        mark_session_completed: 'Markera en gång som använd',
        mark_session_completed_confirmation: 'Vill du säkert markera en terapigång som använd?',
        paying_party: 'Betalande enhet',
        zone_instructions: 'Instruktioner för området',
        active_assignments: 'Aktiva övningar',
        assignment_history: 'Avklarade övningar',
        assignments_waiting: 'Väntande övningar',
        assignments_history: 'Uppgiftsarkiv',
        feedback_surveys: 'Responsblanketter',
        add_assignment: 'Lägg till övning',
        edit_duration: 'Redigera varaktighet',
        set_ending_date: 'Ange slutdatum',
        response_dates: 'Terapeutens svarsdagar',
        all_assignments_done: 'Alla övningar gjorda!',
        no_new_messages: 'Inga nya meddelanden',
        no_new_assignments: 'Inga nya övningar',
        no_new_msg_or_assignments: 'Inga nya meddelanden eller övningar',
        no_new_posts: 'Inga nya meddelanden',
        end_report: 'Terapiperioder som snart tar slut',
        start_questionnaire_done: 'Inledande kartläggning gjord',
        assignment_has_been_added: 'Övning tillagd i terapiperioden!',
        assignment_is_already_added: 'Övningen finns redan i terapiperioden!',
        assginment_returned: 'Harjoitus palautettu',
        assignment_return_error: 'Harjoituksen palautus epäonnistui',
        back_to_period: 'Tillbaka till terapiperioden',
        confirm_period: 'Bekräfta starten av terapisessionen',
        confirm_period_text: 'Bekräfta starten av sessionen',
        start_questionnaire: 'inledande kartläggningsundersökning',
        cancel_period_starting: 'Avbryt starten',
        confirm_cancel_period_starting: 'Är du säker på att du vill avbryta starten?',
        search_all: 'Sök i hela Artikulationsskolan',
        write_diarium_number_start_period: 'Mata in Diariumnummer och påbörja terapiperiod.',
        diarium_number: 'Diariumnummer',
        select_module_category: 'Välj modulens kategori',
        select_category: 'Välj kategori',
        start_period: 'Starta period.',
        error_messages: {
            select_category: 'Välj kategori.',
            write_diarium_number: 'Skriv Diariumnummer',
        },
        period_duration: 'Terapiperiodens varaktighet: ',
        change_period_duration: 'Förläng terapiperiodens varaktighet',
        share_query: 'Jaa kysely',
        query_shared: 'Kysely jaettu onnistuneesti!',
        message_deleted: 'Viesti poistettu',
        period_count: 'Antal perioder',
        latest_sent_message: "Senast skickat meddelande",
    },
    periods: {
        period_time: 'Terapiperiodens varaktighet',
        search: 'Sök terapiperioder',
        not_found: 'Inga terapiperioder hittades',
        periods_passive: 'Passiva terapiperioder',
    },
    client: {
        period_history: 'Tidigare terapiperioder',
        guardians: 'Vårdnadshavare',
        guardian: 'Vårdnadshavare',
        ageGroupType: {
            ageGroupType1: '3v0kk-3v11kk',
            ageGroupType2: '4v0kk-4v11kk',
            ageGroupType3: '5v0kk-5v11kk',
            ageGroupType4: '6v0kk-6v11kk',
            ageGroupType5: 'I skolåldern',
            ageGroupType6: 'Småbarnspedagogisk beskrivning av ett barn för en logoped',
        },
    },
    zone: {
        notes: 'Anteckningar',
        create_zone: 'Skapa område',
        zone_name: 'Kommun',
        zone: 'Region',
        area: 'Arbetsområden',
        evaluation_zones: 'Palvelutarpeenarvio alueet',
    },
    assignment: {
        approved: 'Godkänd',
        to_be_completed: 'Ska fyllas i',
        by: 'senast',
    },
    evaluations: {
        sent_licenses: 'Inlämnade licenser',
        waiting_evaluation: 'Väntar på bearbetning',
        evaluated: 'Bearbetas',
        evaluate: 'Dekorerad',
        age_group: 'åldersgrupp',
        remove_reservation: 'Ta bort reservation',
        created_at: 'Fylld',
    },
    // TODO add translations.
    evaluation: {
        evaluate: 'Gör en bedömning av servicebehov',
        evaluations_answers: 'Svar till blanketten',
        notes: 'Anteckningar',
        responsible_therapist: 'Ansvarsterapeut',
        directed: 'Rekommendation om fortsättning',
        submit_evaluation: 'Mark bearbetat',
        submit_evaluation_confirmation: 'Vill du markera som utvärderad?',
        cancel_evaluation: 'Avbryt utvärdering',
        cancel_evaluation_confirmation: 'Vill du avbryta utvärderingen?',
        questionnaire_selection: {
            text1: 'Via den här blanketten utreder vi ert barns terapibehov.',
            text2: 'Välj ert barns ålder för att komma till rätt blankett.',
            text3: 'Välj blankett.',
            text4: 'Till utvärderingen',
        },
    },
    messages: {
        mark_read: 'Markera som läst',
        post_for_therapists: 'Ett nytt meddelande för terapeuterna skapades',
        post_updated: 'Meddelandet har ändrats!',
        post_removed: 'Meddelandet har tagits bort!',
        new_zone_created: 'Det nya området har skapats!',
        topic: 'Ämne',
        new_message: 'Nytt meddelande',
        write_message: 'Skriv ett meddelande',
        new_message_template: 'Ny meddelandebas',
        new_messages: 'Nya meddelanden',
        attachments: 'Bilagor',
        admin_messages: 'Adminmeddelanden',
        sending_group_messages: 'Skicka gruppmeddelande',
        templates: 'Meddelandemallar',
        template: 'Meddelandemall',
        select_message_template: 'Välj meddelandemall',
        select_client_groups: 'Välj klientgrupper',
        zone_templates: 'Områdenas meddelandemallar',
        common_templates: 'Allmänna meddelandemallar',
        personal_templates: 'Egna meddelandemallar',
        general_templates: 'Yhteiset viestipohjat',
        no_messages: 'Inga meddelanden',
        type: 'Typ',
        message_content: 'Meddelande Innehåll',
        client_group: 'Välj en kundgrupp',
        send_message_through_platform: 'Skicka ett meddelande till dina egna kunder via plattformen',
        message_sent: 'Meddelande skickat',
        messages_subject: 'Meddelandets Ämne',
        own_customers: 'Alla egna kunder',
        ends: 'slut',
        post_created: 'Meddelande skickat!',
        post_content: 'Meddelandets innehåll',
        sended: 'Skickat',
        no_guardian_found: 'Ingen vårdnadshavare med de här uppgifterna kunde hittas, tillägg misslyckades.',
        zone_updated_success: 'Alueen päivitys onnistui.',
        zone_updated_failed: 'Alueen päivitys epäonnistui.',
        message_history: 'Meddelandearkiv',
    },
    profile: {
        customer_updated_successfully: 'Käyttäjä muokattiin onnistuneesti!',
        is_active: 'Användarens konto är aktivt',
        user_active: 'Användarkontot är nu aktivt',
        user_deactive: 'Användarkontot har deaktiverats.',
        email_again: 'E-post igen',
        user_created: 'Användare skapad',
        user: 'Användare',
        email_address: 'E-postadress',
        user_names: 'Användarens för- och efternamn',
        first_and_last_name: 'För- och efternamn',
        birth_year: 'Födelseår',
        name: 'Namn',
        first_name: 'Förnamn',
        last_name: 'Efternamn',
        ssn: 'Personnummer',
        ssn_example: '010101-123D',
        email: 'E-post',
        phone: 'Telefonnummer',
        personal_availability: 'Egen tillgänglighet i onlineterapi',
        clients: 'om klienten',
        response_days: 'Svarsdagar',
        response_day: 'Svarsdagen',
        therapist_introduction: 'Terapeutens presentation',
        therapist_intro_video: 'Terapeutens presentationsvideo',
        absence_notice: 'Meddela om frånvaro',
        absence_time: 'Tid för frånvaro',
        starting_day: 'Startdag',
        ending_day: 'Slutdag',
        absence_message: 'Meddelande om frånvaro',
        autoreply: 'Frånvaromeddelande aktiverat',
        message_sending: 'Skicka meddelande',
        confirm_absence: 'Bekräfta frånvaro',
        no_absence_notice: 'Ei poissaloilmoitusta',
        change_password: 'Byta av lösenord/användarnamn',
        extend_therapy_periods: 'Förläng terapiperioder',
        days: 'dagar',
        edit_profile: 'Redigera profil',
        edit_info: 'Redigera information',
        nickname: 'Pseudonym',
        guardian_info: 'Vårdnadshavares information',
        edit_password: 'Lösenordsändring',
        old_password: 'Gammalt lösenord',
        new_password: 'Ett nytt lösenord',
        new_password_again: 'Nytt Lösenord Igen',
        show_password: 'Visa lösenord',
        save_password: 'Spara lösenordet',
        birthday: 'Födelsedatum',
        open: 'Öppna profilen',
        select_language: 'Välj klientens språk',
        admin: 'Administratör',
        status: {
            s61d42db9ad4a0c39168efe80: 'Ylläpitäjä',
            s61d42db9ad4a0c39168efe72: 'Lisenssin hallinnoija',
            s61cdd00ead4a0c39168daee1: 'Terapeutti',
        },
        write_email: 'Mata in din e-postadress',
        incorrect_email: 'Felaktig e-postadress!',
        success_email: 'Meddelande skickat till mottagaren. Om du inte får meddelandet, kontrollera att du har angett samma e-postadress som under registreringen. Försök igen.',
        change_passw: 'Byt lösenord',
        different_passwords: 'Lösenorden stämmer inte överens',
        password_change_success: 'Byte av lösenord lyckades!',
        password_change_failed: 'Bytet av lösenord misslyckades. Orsaken kan vara en föråldrad länk.',
        passw_change_failed: 'Byte av lösenord misslyckades.',
        password_change_info: 'Lösenordet måste innehålla minst 14 tecken, en liten bokstav, en stor bokstav och ett specialtecken.',
        password_change_error: 'Något gick fel. Kontrollera att lösenordet har minst 14 tecken, en liten bokstav, en stor bokstav och ett specialtecken.',
    },
    languages: {
        finnish: 'På finska',
        swedish: 'På svenska',
    },
    material_library: {
        material_removed: 'Materiaali on poistettu',
        material_updated: 'Materiaali muokattiin onnistuneesti!',
        vimeo_link: 'Vimeo linkki',
        game_id: 'Pelin ID',
        material_name: 'Materiaalin nimi',
        search_by_name: 'Etsi materiaalin nimellä',
        get_materials: 'Hae ja selaa materiaaleja',
        choose_category: 'Valitse kategoria',
        write_tags: 'Kirjoita uusi tagi...',
        choose_material_type: 'Valitse materiaalin tyyppi',
        material_link: 'Linkki materiaaliin',
        description: 'Kuvaus',
        search_materials: 'Selaa materiaaleja',
        upload_new_material: 'Lataa uutta materiaalia',
        open_material: 'Öppna materialet',
        open_material_to_new_window: 'Öppna materialet i ett nytt fönster',
        open_game_to_new_window: 'Öppna spelet i ett nytt fönster',
        content: 'Innehåll',
        assignment: 'Övning',
        client_instructions: 'Instruktioner till klienten',
        instructions: 'Instruktioner',
        share_assignment: 'Dela övning',
        select_customer: 'Välj klient',
        wide_search: 'Laaja haku',
        module: 'Moduli',
        category: 'Kategoria',
        phase: 'Terapian vaihe',
        language: 'Språk',
        material_type: 'Materiaalityyppi',
        tags: 'Tagit',
        hierarchies: 'Hierarkiat',
        free_text_search: 'Vapaa tekstihaku',
        game: 'Spel',
        video: 'Video',
        text: 'Text',
        all: 'Alla',
        search_results: 'hakutulosta',
        materials: 'Materiaalit',
        upload_new_material_message: 'Lataa uutta materiaalia palvelimelle',
        update_material: 'Muokkaa materiaalia',
        upload_info: {
            video: 'Videoiden täytyy olla vimeo linkki',
            game: 'Pelin ID täytyy olla numero',
            text: 'Tekstitiedostot voivat olla pdf, doc, docx, odt, txt, rtf, md, ods, xls tai xml-tyyppiä.',
            choose_link_or_game: 'Valittuasi materiaalin tyyppi, valitse vimeo linkki, tekstitiedosto tai pelin ID',
        },
        upload_wargning_messages: {
            name_required: 'Materiaalin nimi on pakollinen',
            type_required: 'Materiaalin tyyppi on pakollinen',
            module_required: 'Materiaalin moduuli on pakollinen',
            option_info: 'Valitse joko materiaalin linkki tai tiedosto koneelta',
            wrong_file_format: 'Väärä tiedostomuoto',
            something_went_wrong: 'Jotain meni vikaan, yritä uudestaan',
            success: 'Materiaali lisätty!',
            game_id_is_not_number: 'Pelin ID ei ole numero',
            link_or_game_id: 'Linkki tai pelin ID',
        },
    },
    start_questionnaire: {
        info: {
            message: 'Du kan nu fortsätta att göra videor och inledande kartläggning. Vänligen ge tillräckligt med tid (20-30) minuter för att göra dem.',
            message2: 'Efter att ha filmat filmerna och svarat på den första enkäten kommer logopeden att kontakta dig inom en vecka. Du får också de första hemövningarna från honom.',
        },
        all_done_message: {
            message: 'Allt är klart!',
            message2: 'Talterapeuten kontaktar er inom en vecka!',
        },
    },
    invitation: {
        license_type_checker: {
            label: "Lisenssin tyyppi"
        },
        extension_license: {
            not_checked: 'Lisenssin tyyppi selvitetään, kun moduuli, ja henkilötunnus on annettu',
            pending: 'Lisenssin tyyppiä selvitetään...',
            true: 'Lisenssi ON jatkolisenssi',
            false: 'Lisenssi EI OLE jatkolisenssi'
        }
    },
    // TODO: add translations
    licenses: {
        create_new_licensezone: 'Luo uusi lisenssialue',
        create_licences: 'Luo lisenssejä',
        search_licences: 'Selaa lisenssejä',
        control_licences: 'Lisenssien hallinta',
        licenses: 'Licenser',
        share_license_to_client: 'Skicka klientlicens',
        pick_license: 'Välj en licens',
        license_code: 'Licenskod',
        valid_until: 'Bokningen avslutas',
        free_licenses: 'Fri licenser',
        reserved_licenses: 'Reserverad licenser',
        used_licenses: 'Begagnade licenser',
        license_id: 'LicenseId',
        license_key: 'Lisenssi avain',
        license_valid: 'License giltig till',
        reserved_till: 'Reserverad fram tills',
        invitation_valid_until: 'Giltighetstid för inbjudan (i dagarna)',
        used: 'Begagnade',
        print: 'Skriv ut instruktionerna',
        license_shared_message: 'Licens distribueras framgångsrikt!',
        remove_license_confirmation: 'Haluatko varmasti poistaa lisenssin?',
        remove_licenses_confirmation: 'Haluatko varmasti poistaa lisenssit?',
        license_type: 'Lisenssin tyyppi',
        reserver_email: 'Varaajan sähköposti',
        license_left: 'Lisenssiä jäljellä',
        extension_license: 'Jatkolisenssi',
        evaluation_error_message: 'Palvelutarpeen arvio lisenssille ei voida luoda jatkolisenssiä.',
        general_license_error_message: 'Jotain meni vikaan. Varmista että henkilötunnus on oikein ja että asiakkaalla on olemassa oleva periodi, jolle jatkolisenssi luodaan.',
    },
    therapy: {
        therapy_types: 'Terapiamuodot',
        module: 'Moduuli',
        therapy_type: 'Terapiamuoto',
        select_therapy_type: 'Valitse terapiamuoto',
        select_module: 'Valitse moduuli',
        select_modules: 'Valitse moduulit',
        select_zone: 'Valitse alue',
        type: 'Typ av terapi',
        select_therapy_module: 'Valitse terapiamoduli',
        types: {
            all_periods: 'Alla avsnitt',
            all_customers: 'Alla kunder',
            new_periods: 'Nya avsnitt',
            all: 'Alla',
            online: 'Nätterapi',
            hybrid: 'Hybridterapi',
            remote: 'Distansterapi',
            onsite: 'Närterapi',
            evaluations: 'Bedömning av servicebehov',
            pt_valuation: 'Pt-bedömning',
            maternity_clinic_follow_up: 'Konsultationsuppföljning',
            therapy_session: 'Er terapiperiod',
            specialized_medical_care: 'Special vård',
            no_actions: 'Ingen action',
        },
        modules: {
            m61f01cbc6fcb1a2901ede7e1: 'Artikulationsfel',
            m61f0fb706fcb1a2901ede7e6: 'Ordförråd',
            m61f0ffc86fcb1a2901ede7f1: 'Språklig medvetenhet',
            m61f0fff06fcb1a2901ede7f3: 'Selektivt ätande',
            m61f1002e6fcb1a2901ede7f5: 'Flerspråkighet',
            m61f1007f6fcb1a2901ede7f7: 'Tidig interaktion',
            m622202c82b8a5f60e7894027: 'Bedömning av servicebehov',
            m61f0ff8f6fcb1a2901ede7ef: 'MVP',
            m61f0fbb76fcb1a2901ede7e8: 'Puheen ymmärtäminen',
            m61f0ff3b6fcb1a2901ede7ed: 'Kerronta',
            m6221fb122b8a5f60e7893d12: 'Äännekoulun ärräkurssi',
            m6221fb252b8a5f60e7893d1d: 'Äännekoulun älläkurssi',
            m6221fdb42b8a5f60e7893e1d: 'Äännekoulun R-klinikan Ärräkurssi',
            m622201bc2b8a5f60e7893fb9: 'Terapeutin omat asiakkaat',
            // TODO: add tranlations
            basic_introductions: {
                // Same for all online modules
                title1: 'Videoinspelning:',
                text1: 'Filma följande videon av ert barn. Se till att barnets ansikte syns bra och är väl upplyst. Filma gärna lite underifrån, så att terapeuten kan se munnen och tungans rörelser. Håll videona så korta som möjligt.',
                text2: 'Innan ni filmar rekommenderar vi att ni kontrollerar era videoinställningar, så att filen ni skickar inte blir för stor och går lättare och snabbare att ladda upp. Använd gärna telefonens frontkamera för att filma.',
                text3: 'Skriv ditt meddelande och tryck på skicka, eller välj en fil att skicka till terapeuten. Efter att ni skickat in alla bedömningsvideon får ni inom en vecka ett svar från en av våra talterapeuter. Om ni inte fått svar inom en vecka kan ni kontakta Artikulationsskolans kundtjänst via asiakaspalvelu@aannekoulu.fi.',
                text4: 'Uppladdningen av videon kan ta en stund, särskilt om filen är stor. Vänta tills systemet meddelar att allting är klart.',
                text5: 'Videona konverteras beroende på deras format, och kan efter det spelas upp rakt i webbläsaren. Tjänsten stöder formaten mp4, mov, wmv, avi, m4v, ogg, 3gp, webm, mkv och mpg. Kontakta kundtänst på siakaspalvelu@aannekoulu.fi om du stöter på problem.',
                text6: 'Videona filmas tillsammans.',
                text7: 'Videoinstruktioner',
                text8: 'Ladda upp video',
            },
            pronunciation_errors: {
                // Äännevirheet - Ruotsiksi ei löydy muuta kuin tämä
                title1: 'Videon för den inledande bedömningen:',
                text1: 'Titta på följande instruktionsvideo och spela in ert barn då hen gör samma övning. Istället för en flinga kan man också använda en liten, platt brödbit eller motsvarande.',
                text2: 'Spela in när ditt barn säger följande ord. Be barnet upprepa orden efter en vuxen även om barnet kan läsa dem själv: vatten, mommo, Mumin, alla, Ulla, Ada, ajaa?',
                text3: 'Ess (S), Åsa, sen, sol, häst, mus, snögubbe, la-la, le, boll, soffa, fem, Ärr (R), Robot, morra, gurka, ekorre, krokodil, drake, kan, Kicki, vecka, kokkooo!, tack, häxa, sjunga, kök.',
                text4: 'Barnets spontantal. Barnet kan berätta t.ex. om en hobby eller något annat som hen är intresserad av.',
            },
            vocabulary: {
                // Sanavarasto
                title1: 'OHJEET ALKUKARTOITUSVIDEOIDEN TEKEMISEEN:',
                title2: 'Kuvaa seuraavat kaksi videota puheterapeutillenne ja lähetä ne viestien kautta:',
                text1: 'Kuvaa video, jossa lapsi kertoo itsestään. Voit kysyä lapselta: mistä leikeistä sinä tykkäät? Keitä sinun perheeseesi kuuluu? Kerro lempilelustasi, minkälainen se on? Mitä haluaisit kysyä puheterapeutilta?',
                text2: 'Kuvaa toinen video, jossa lapsi kertoo jostain kirjan kuvasta, mitä siinä tapahtuu. Voit rohkaista nimeämään kuvasta löytyviä asioita ja kysyä lapselta lisäkysymyksiä: mitä lapset tekevät? Mitä lapset ajattelevat?',
                text3: 'Katso tästä tervetuloa -video:',
            },
            linguistic_awareness: {
                // kielellinen tietoisuus
                title1: 'Tervetuloa kielellisen tietoisuuden taitojen kuntoutukseen!',
                title2: 'Harjoittelu aloitetaan näin:',
                text1: 'Klikkaa VIESTIT (puhekupla). Täältä lähetetään viestit puheterapeutille myös jatkossa.',
                text2: 'Täyttäkää aluksi alkukartoituskysely, joka löytyy heti "VIESTIT"-osiosta alhaalta kohdasta "KYSELYT"  (kysely löytyy nimellä “Kielellisen tietoisuuden ja lukitaitojen alkukartoitus")',
                text3: 'Kyselyn täyttämisen jälkeen tehkää alkukartoitusvideot. Ohjeet alkukartoitusvideoihin löydätte kyselystä.',
                text4: 'Ladatkaa videot "VIESTIT" -osioon, kohdasta "Lataa tiedosto"',
                text5: 'Puheterapeutti vastaa teille, kun on katsonut kyselyvastauksenne ja videot. Saatte vastauksen noin viikon kuluessa. Saatte häneltä silloin ensimmäiset tehtävät.',
                text6: 'Saatte sähköpostiinne viestin, kun puheterapeutti on vastannut teille.',
            },
            picky_eater: {
                // 'Valikoiva syöjä'
                title1: 'Tervetuloa Äännekoulun Valikoiva syöjä -verkkokuntoutukseen!',
                title2: 'Tutustu puheterapeutteihin alla olevista videoista:',
                title3: 'Mitä verkkokuntoutus tarkoittaa?',
                title4: 'Ensimmäiset tehtävät - aloita tästä',
                text1: 'Hienoa, että olet löytänyt tiesi tähän verkkokuntoutukseen. Tavoitteemme tässä kuntoutuksessa on, että perheesi ruokailutilanteet olisivat yleisesti ottaen miellyttäviä yhdessäolon hetkiä, ja että lapsellasi olisi niissä luottavainen ja turvallinen olo.',
                text2: 'Jotta ruokailutilanteista voi tulla mukavia hetkiä, pyrimme yhdessä siihen, että lapsesi pelot ja ennakkoluulot ruokailua ja uusia ruokia kohtaan lieventyvät, vähenevät tai poistuvat aikuisen tai aikuisten avulla. Me puheterapeutit autamme sinua tässä tehtävässä koko kuntoutusjakson ajan.',
                text3: 'Verkkokuntoutus tarkoittaa sitä, että viestittely tapahtuu ainoastaan videoiden avulla - tietoturvallisesti Äännekoulun verkkokuntoutusympäristössä.Kun olette lähettäneet puheterapeutille alkuarviovideot (näistä seuraa ohjeet jäljempänä), puheterapeutti vastaa teille videolla ja kertoo, miten kuntoutus etenee. Hän antaa teille uudet tehtävät, jotka raportoitte taas seuraavalla viikolla joko videoilla tai viestillä. Puheterapeutti vastaa teille jälleen videolla. Jakso jatkuu tällä tavalla 3 kuukauden ajan.',
                text4: 'Jakso sisältää 11 moduulia, jotka kannattaa jokainen käydä läpi. Näin saat runsaasti tietoa siitä, mitä syitä lapsesi valikoivuuden taustalla voi olla, ja mihin asioihin kotona on mahdollista vaikuttaa. Kuntoutus tarjoaa sinulle myös ymmärrystä, jotta osaisit toimia lapsesi kanssa tavalla, joka vähentää lapsen syömistilanteissa kokemaa ahdistusta ja pelkoa uusia ruokia kohtaan. Tiedon lisäksi jokainen moduuli sisältää myös konkreettisia vinkkejä arkeen.',
                text5: '1. Mikäli lapsesi jo puhuu: kuvaa lapsestasi lyhyt, korkeintaan 2 min. video (voit kuvata vaikka puhelimella), jossa kuuluu lapsen spontaania puhetta. Voit esimerkiksi pyytää lastasi kertomaan jostain, mistä hän tykkää tai mitä hän on tehnyt eilen/tänään/viikonloppuna.',
                text6: '2. Kuvaa lapsesi ruokailutilanteesta korkeintaan 5 min. video siten, että lapsen kasvot ja ylävartalo näkyvät hyvin. Videolla olisi hyvä näkyä sekä syömistä että juomista.',
            },
            early_language_development: {
                // 'Varhainen kielenkehitys'
                title1: 'Tervetuloa varhaisen kielenkehityksen nettiterapiaan!',
                title2: 'Ensimmäiset tehtävät:',
                text1: 'Kuvaa video jossa lapsi leikkii sinun kanssasi.',
                text2: 'Video voi olla noin 1-2 minuuttia pitkä.',
                text3: 'Voit jutella lapselle ja kysellä kysymyksiä.',
                text4: 'Katso tästä tervetuloa-video:',
            },
        },
        categories: {
            R: 'R-ljudet',
            S: 'S-ljudet',
            L: 'L-ljudet',
            K: 'K-ljudet',
            M_P: 'M och P ljud',
            J: 'J-ljudet',
            front_vowels: 'Främre vokaler',
            V: 'V-ljudet',
        },
    },
    customer_service: {
        contact_info: 'Kundtjänst kontaktinformation',
        chat: 'Chat',
        excercise_books: 'Övningsböcker',
        links: 'Länkar',
        feedback: 'Ge feedback',
        service_time: 'vardagar kl',
        tech_suport: 'teknisk support',
        address: 'på adressen',
        read_more: 'Läs mer',
        appointment: 'Tidsbokning',
        blog: 'Blogg',
        facebook_site: 'Äännekoulu Facebook',
    },
    common: {
        remove_user: 'Poista käyttäjätili käytöstä',
        sure: 'Oletko varma?',
        municipality_missing: 'kunta puuttuu',
        file_size_too_large: 'Tiedoston maksimikoko 1 GB ylittyi',
        firstname_missing: 'Etunimi puuttuu',
        lastname_missing: 'Sukunimi puuttuu',
        email_missing: 'Sähköposti puuttuu',
        ended: "Avslutad",
        phone_missing: 'Puhelinnumero puuttuu',
        attachment_missing: 'Bifogad fil saknas',
        wrong_file_format: 'Väärä tiedostomuoto',
        emails_dont_match: 'sähköpostit eivät täsmää',
        contact_person_phone: 'Yhteyshenkilön puh. numero',
        contact_person_email: 'Yhteyshenkilön sähköposti',
        contact_person_name: 'Yhteyshenkilön nimi',
        new_licence_controller_was_created: 'Uusi lisenssin hallitsija luotiin onnistuneesti',
        new_admin_was_created: 'Uusi admin luotiin onnistuneesti',
        creating_failed: 'Käyttäjän luominen epäonnistui, yritä uudelleen!',
        fill_form: 'Täytä kaikki pakolliset kentät',
        create_new_therapist: 'Luo uusi terapeutti-käyttäjä',
        create_new_admin: 'Luo uusi admin-käyttäjä',
        create_new_licence_controller: 'Luo uusi lisenssin hallitsija',
        new_therapist_was_created: 'Uusi terapeutti luotiin onnistuneesti',
        choose_zone: 'Valitse työskentelyalue',
        choose_evaluation_zone: 'Valitse palvelutarpeenarvio alue',
        search_users: 'Selaa käyttäjiä',
        therapist_instructions: 'Ohjeet terapeutille',
        customer_instructions: 'Ohjeet asiakkaalle',
        required: 'pakollinen',
        search: 'Haku',
        search_result: 'Hakutuloksilla',
        yes: 'Ja',
        no: 'Nej',
        confirm: 'Bekräfta',
        period: "Period",
        remove_post: 'Poista tiedote',
        post_are_you_sure: 'Oletko varma että haluat poistaa tiedotteen?',
        want_to_continue: 'Är du säker på att du vill fortsätta',
        are_you_sure: 'Är du säker på att du vill sluta skicka övningen?',
        date_format: 'DD.MM.YYYY',
        date_mask: '__.__.____',
        date_time_format: 'DD.MM.YYYY HH:mm',
        on: 'På',
        off: 'Av',
        gone: 'Jag är borta',
        instructions: 'Instruktioner',
        optional: 'frivillig',
        contact_info: 'Kontaktinformation',
        last_modified: 'Senast redigerad',
        page: 'Sida',
        days: {
            mon: 'Måndag',
            tue: 'Tisdag',
            wed: 'Onsdag',
            thu: 'Torsdag',
            fri: 'Fredag',
            sat: 'Lördag',
            sun: 'Söndag',
        },
        days_short: {
            mon: 'MÅN',
            tue: 'TIS',
            wed: 'ONS',
            thu: 'TORS',
            fri: 'FRE',
            sat: 'LÖR',
            sun: 'SÖN',
        },
        save_success: 'Sparade lyckades',
        save_failed: 'Misslyckades med att spara',
        delete_success: 'Poisto onnistui',
        delete_failed: 'Poisto epäonnistui',
        select_all: 'Valitse kaikki',
        months: 'månader',
        send_failed: 'Sändning misslyckades',
        send_failed_contact_customer_service: 'Sändning misslyckades. Kontakta oss: asiakaspalvelu (at) aannekoulu.fi',
        hide: 'Piilota',
    },
    account: {
        users: 'Användare',
        welcome: 'Välkommen till Artikulationsskolans artikulationsskolan!',
        enter_license_code: 'Ange licenskoden',
        license_code: 'Licenskoden',
        enter_ssn: 'Fyll i terapiperiodens lösenord',
        wrong_ssn: 'Fel lösenord',
        ssn: 'Instruktioner finns i inbjudan som kom via e-post.',
        i_already_have_account: 'Jag har redan ett konto',
        child_first_name: 'Barnets förnamn',
        child_last_name: 'Barnets efternamn',
        parent_first_name: 'Vårdnadshavarens förnamn',
        parent_last_name: 'Vårdnadshavarens efternamn',
        client_first_name: 'Kundens förnamn',
        client_last_name: 'Kundens efternamn',
        enter_contact_info: 'Fyll i kontaktuppgifter',
        create_account: 'Registrera dig på artikulationsskolan',
        select_username: 'Välj användarnamn',
        select_password: 'Välj lösenord',
        password_again: 'Skriv lösenordet en gång till',
        approve_usage: 'Jag godkänner att videona jag skickar får delas till andra talterapeuter på Artikulaionsskolan.',
        approve_terms: 'Jag godkänner användarvillkoren',
        registration_success: 'Tack för din registrering!',
        registration_success_instruction: 'Du kan nu fortsätta göra videor och/eller första kartläggning genom att logga in på tjänsten.',
        unqualified_username: 'Användar-ID är inte giltigt!',
        new_guardian_added: 'Uusi huoltaja lisätty',
        approve: 'Acceptera',
    },
    actions: {
        search_by_name: 'Etsi käyttäjän nimellä',
        create_user: 'Luo käyttäjä',
        look: 'Sök',
        select_file: 'Valitse tiedosto',
        upload_material: 'Bifoga filer',
        stop: 'Avbryta',
        cancel: 'Avbryt',
        clear: 'Töm',
        clear_selections: 'Rensa valen',
        send: 'Sänd',
        search: 'Sök',
        save: 'Spara',
        continue: 'Fortsätt',
        create_account: 'Skapa konto',
        read_terms: 'Läs användarvillkoren',
        download: 'Ladda ner',
        delete: 'Radera',
        next: 'Nästa',
        previous: 'Tidigare',
        return_assignment: 'Återställ övningen',
        return: 'Skicka',
        back: 'Tillbaka',
        create_new_therapist_post: 'Luo uusi tiedote terapeuteille',
        create_post: 'Luo tiedote',
        modify: 'Muokkaa',
        open: 'Avaa',
        answer: 'Vastaa',
        delete_video: 'Haluatko varmasti poistaa videon?',
        move: 'Siirrä',
        delete_defaultMessage: 'Haluatko varmasti poistaa viestin?',
    },
    questionnaire: {
        no_questions: 'Inga frågor ställda',
        unanswered_questions: 'Sinulla on vastaamaton kysely',
    },
    questionnaireTypes: {
        startQuestionnaire: 'Alkukartoituskysely',
        evaluationQuestionnaire: 'Palvelutarpeen arviot',
        interimSurvey: 'Välikysely',
        finalSurvey: 'Loppukysely',
    },
    admin: {
        inquiry: {
            questionnaires: 'Kyselyt',
            browse_questionnaires: 'Selaa kyselyitä',
            upload_new_inquiry_to_server: 'Lataa uusi kysely',
            update_inquiry_to_server: 'Muokkaa kyselyä',
            select_inquiry_type: 'Valitse kyselyn tyyppi',
            link_to_inquiry: 'Linkki kyselyyn',
            write_material_url: 'Kirjoita tähän materiaalin URL-osoite',
            generated_inquiry: 'Generaattorilla tehty kysely',
            upload_inquiry: 'Lataa kysely',
            answer_the_survey_more_than_once: 'Kyselyyn voi vastata useamman kerran',
            link_to_query_required: 'Länk till enkäten är obligatorisk. Kontrollera även att webbadressen börjar med http:// eller https://',
            search_questionnaires: 'Hae kyselyitä',
            search_by_name: 'Etsi kyselyn nimellä',
            delete_questionnaire: 'Poista kysely',
            delete_questionnaire_confirmation: 'Oletko varma, että haluat poistaa kyselyn',
            open_survey_template: 'Avaa kyselypohja',
        },
        license: {
            zone_name_generated: 'Alueen tunnus määräytyy kunnan nimen mukaan.',
            create_new_license: 'Luo uusi lisenssi',
            create_license: 'Luo lisenssi',
            license_is_for: 'Kenelle lisenssi luodaan',
            license_count: 'Lisenssien määrä',
            times_of_use: 'Käyttökerrat',
            new_licenses_created: 'Uudet lisenssit on luotu',
            new_licenses_created_failed: 'Uusien lisenssien luonti epäonnistui',
            select_therapist: 'Valitse terapeutti',
            license_created: 'Lisenssi luotu',
            free: 'Vapaa',
            reserved: 'Varattu',
            used: 'Käytetty',
            zone_therapist: 'Alueen ID / Terapeutin nimi',
            zone_name: 'Alueen ID',
            select_state: 'Valitse lisenssin status',
            created_licenses: 'Luodut lisenssit',
            zone_info: 'HUOM! Luo ensin alue ja yhteyshenkilö ja sen jälkeen lisenssin jakaja, jolla on oikeus jakaa luotuja lisenssejä asiakkaille.',
            select_license_valid_until: 'Valitse voimassaolo',
            zone_details: 'Alueen tiedot',
            zone_licenses: 'Alueen lisenssit',
            license_valid_until: 'Voimassaolo päättyy',
            license_code: 'Lisenssikoodi',
            status: 'Status',
        },
        periods: {
            move_periods: 'Siirrä terapiajaksoja',
            select_therapist: 'Valitse terapeutti jolle terapiajaksot siirretään',
        },
        therapist: {
            is_overflow_therapist: 'Ylivuotoasiakkaiden terapeutti',
        },
    },
    error: {
        municipality: 'Kunta -kenttä puuttuu',
        first_name: 'Etunimi -kenttä puuttuu',
        last_name: 'Sukunimi -kenttä puuttuu',
        email: 'Sähköposti -kenttä puuttuu',
        phone: 'Puhelinnumero -kenttä puuttuu',
        license_count: 'Lisenssien määrä pitää olla enemmän kuin 0',
        zone: 'Alue/terapeutti -kenttä puuttuu',
        modules: 'Moduuli -kenttä puuttuu',
        license_valid_until: 'Voimassaolo -kenttä puuttuu',
        name_reserved: 'Nimi on jo käytössä. Valitse toinen',
        name: 'Nimi -kenttä puuttuu',
        periods_count: 'Valittujen terapiajaksojen määrä pitää olla enemmän kuin 0',
        empty_result: 'Ei tuloksia',
    },
    help_chat: {
        send_help_chat_message: 'Lähetä liitteet pikahelppiin',
        success_message: 'Liitteet lähetetty onnistuneesti',
        help_chat_messages: 'Uutta pikehelppi viestiä',
    },
};
